import React from "react"

import Layout from "../components/Layout"
import Heading from "../components/Layout/Heading"
import SubHeading from "../components/Layout/SubHeading"
import SEO from "../components/SEO"

import doug from "../images/staff/doug_solomon.jpg"
import craig from "../images/staff/craig_solomon.jpg"
import neil from "../images/staff/neil_solomon.jpg"
import mason from "../images/staff/mason_mawer.jpg"

import "./our-staff.scss"

const OurStaff = (props) => (
  <Layout location={props.location}>
    <SEO
      title="Our Staff"
      description="Here are our friendly staff that will gladly help you with your
      vehicle purchase"
    />
    <Heading title="Our Staff" />
    <SubHeading
      subHeading="Here are our friendly staff that will gladly help you with your
          vehicle purchase"
    />
    <div className="row">
      <div className="col-12 col-md-6 text-center">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <img className="staff-image" src={doug} alt="Doug Solomon" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <p className="staff-name">Doug Solomon</p>
            <p className="staff-location">Solomon Motors City</p>
            <p>
              Doug started in the motor industry at Byrt Ford in Wollongong as a
              Spare Parts trainee.
            </p>
            <p>
              He then worked for the Original Solomon Motors on Berry Street in
              the Parts Department then progressed to the Sales Department.
            </p>
            <p>Doug then moved to Solomon Motors new premises in 1987.</p>
            <p>
              Doug has worked in multiple areas of the motor industry for over
              40 years.
            </p>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 text-center">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <img className="staff-image" src={craig} alt="Craig Solomon" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <p className="staff-name">Craig Solomon</p>
            <p className="staff-location">Solomons Mid City Autos</p>
            <p>
              Craig started in the motor industry at Hills truck sales in
              Wollongong in 1979.
            </p>
            <p>
              He then worked for one of the longest established Holden
              dealerships in Sydney then moved back home to Nowra where he
              joined Solomon Motors in 1987.
            </p>
            <p>
              The Company opened a second used car dealership in central Nowra
              in 1999 called Solomons Mid City Autos and continues the Solomon
              tradition in providing you with Quality Used Vehicles and Great
              Service.
            </p>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 text-center">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <img className="staff-image" src={neil} alt="Neil Solomon" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <p className="staff-name">Neil Solomon</p>
            <p className="staff-location">Solomon Motors City</p>
            <p>
              Neil was born in the Shoalhaven area and has lived here his whole
              life.
            </p>
            <p>He started at the Solomon Motor Group in 2003 as a yardman.</p>
            <p>
              After 3 years Neil moved into the Sales Department and provides
              his customers with a great purchasing experience.
            </p>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 text-center">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <img className="staff-image" src={mason} alt="Mason Mawer" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <p className="staff-name">Mason Mawer</p>
            <p className="staff-location">Solomons Mid City Autos</p>
            <p>
              Born and Bred in the Shoalhaven area, Mason started work at the
              Solomon Motor Group in early 2003 as a yard hand.
            </p>
            <p>
              Mason moved into the Sales Department after 3 years and prides
              himself on making your next vehicle purchase as smooth as
              possible.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default OurStaff
